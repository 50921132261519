import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Ring Muscle Ups & Turkish Get Ups`}</p>
    <p>{`then,`}</p>
    <p>{`Buy in: 20-Ring Dips (Rx+ 10 Ring Muscle Ups)`}</p>
    <p>{`4 rounds of`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`15-Calorie Assault Bike`}</p>
    <p>{`Cash Out: 20-Ring Dips (Rx+ 10-Ring Muscle Ups)`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`The evening classes are cancelled today.`}</p>
    <p>{`*`}{`Free New Years Day Partner WOD Saturday at 9:00 & 10:15!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      